function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}
import UsersAPI from "../../../Users/UsersAPI";
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";


const prepareUser = (user) => {
  const preparedUser = _objectSpread({}, user);

  if (!preparedUser.password) {
    delete preparedUser.password;
  }
  delete preparedUser.accountLocked;
  delete preparedUser.failedLogins;

  return preparedUser;
};

const newUser = async (user, headers) => {
  try {
    const createdUser = prepareUser(user);
    const requestParams = new RequestParams(createdUser, headers);
    const response = await UsersAPI.new(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const updateUser = async (user, headers) => {
  try {
    const updatedUser = prepareUser(user);
    const requestParams = new RequestParams(updatedUser, headers);
    const response = await UsersAPI.save(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const deleteUser = async (users, headers) => {
  try {
    const requestParams = new RequestParams({ ids: users.map((user) => user._id) }, headers);
    const response = await UsersAPI.delete(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const saveGroup = async (group, headers) => {
  try {
    const requestParams = new RequestParams(group, headers);
    const response = await api.post('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const deleteGroup = async (groups, headers) => {
  try {
    const requestParams = new RequestParams(
      { ids: groups.map((group) => group._id) },
      headers
    );
    const response = await api.delete('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const unlockAccount = async (user, headers) => {
  try {
    const requestParams = new RequestParams({ _id: user._id }, headers);
    const response = await UsersAPI.unlockAccount(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const resetPassword = async (
data,
headers) =>
{
  try {
    if (Array.isArray(data)) {
      const response = await Promise.all(
        data.map((user) => {
          const requestParams = new RequestParams({ email: user.email }, headers);
          return api.post('recoverpassword', requestParams);
        })
      );
      return response;
    }

    const requestParams = new RequestParams({ email: data.email }, headers);
    const response = await api.post('recoverpassword', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const reset2FA = async (
data,
headers) =>
{
  try {
    if (Array.isArray(data)) {
      const response = await Promise.all(
        data.map((user) => {
          const requestParams = new RequestParams({ _id: user._id }, headers);
          return api.post('auth2fa-reset', requestParams);
        })
      );
      return response;
    }

    const requestParams = new RequestParams({ _id: data._id }, headers);
    const response = await api.post('auth2fa-reset', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const get = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const response = await UsersAPI.get(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const getUserGroups = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const response = await api.get('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

export {
  get,
  getUserGroups,
  newUser,
  updateUser,
  deleteUser,
  saveGroup,
  deleteGroup,
  unlockAccount,
  resetPassword,
  reset2FA };