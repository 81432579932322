import { actions } from "../../BasicReducer";
import { t } from "../../I18N";

import * as notifications from "../../Notifications/actions/notificationsActions";
import SearchAPI from "../../Search/SearchAPI";
import {
  getLabelsQuery,
  getReadyToPublishSuggestionsQuery,
  getReadyToReviewSuggestionsQuery } from "../../Settings/utils/suggestions";

import ThesauriAPI from "../ThesauriAPI";
import api from "../../utils/api";
import { RequestParams } from "../../utils/RequestParams";



import { buildLabelCounts, flattenLabelCounts } from "../utils/suggestionQuery";

export function toggleEnableClassification() {
  return async (dispatch, getState) => {
    const thesaurus = getState().thesauri.thesaurus.toJS();
    thesaurus.enable_classification = !thesaurus.enable_classification;
    const _updatedThesaurus = await ThesauriAPI.save(new RequestParams(thesaurus));
    dispatch(
      notifications.notify(
        t(
          'System',
          thesaurus.enable_classification ?
          `You will now see suggestions for ${_updatedThesaurus.name}.` :
          `You will no longer see suggestions for ${_updatedThesaurus.name}.`,
          null,
          false
        ),
        'success'
      )
    );
    dispatch(actions.set('thesauri.thesaurus', _updatedThesaurus));
  };
}

async function getAndPopulateLabelCounts(
templates,
queryBuilderFunc,
assocProp,
countSuggestions = true)
{var _assocProp$name2;
  if (!assocProp) {
    return { totalRows: 0, totalLabels: 0, thesaurus: { propertyName: '', totalValues: {} } };
  }
  const docsWithSuggestions = await Promise.all(
    templates.map((template) => {
      const reqParams = new RequestParams(queryBuilderFunc(template._id, assocProp));
      return SearchAPI.search(reqParams);
    })
  );
  const sanitizedSuggestionsTBPublished = docsWithSuggestions.map((s) => {var _assocProp$name;return (
      buildLabelCounts(s, (_assocProp$name = assocProp === null || assocProp === void 0 ? void 0 : assocProp.name) !== null && _assocProp$name !== void 0 ? _assocProp$name : '', countSuggestions));}
  );
  return flattenLabelCounts(sanitizedSuggestionsTBPublished, (_assocProp$name2 = assocProp === null || assocProp === void 0 ? void 0 : assocProp.name) !== null && _assocProp$name2 !== void 0 ? _assocProp$name2 : '');
}

export function updateCockpitData(_serverRequestParams) {
  return async (dispatch, getState) => {var _state$thesauri$sugge;
    const requestParams = _serverRequestParams !== null && _serverRequestParams !== void 0 ? _serverRequestParams : new RequestParams();
    const state = getState();
    const thesaurus = state.thesauri.thesaurus.toJS();
    const templates = state.templates.toJS();
    const assocProp = (_state$thesauri$sugge = state.thesauri.suggestInfo.get('property')) === null || _state$thesauri$sugge === void 0 ? void 0 : _state$thesauri$sugge.toJS();
    const [
    syncState,
    trainState,
    model,
    docsWithSuggestionsForPublish,
    docsWithSuggestionsForReview,
    docsWithLabels] =
    await Promise.all([
    api.get('tasks', requestParams.set({ name: 'TopicClassificationSync' })),
    ThesauriAPI.getModelTrainStatus(requestParams.set({ thesaurus: thesaurus.name })),
    ThesauriAPI.getModelStatus(requestParams.set({ thesaurus: thesaurus.name })),
    getAndPopulateLabelCounts(templates, getReadyToPublishSuggestionsQuery, assocProp),
    getAndPopulateLabelCounts(templates, getReadyToReviewSuggestionsQuery, assocProp),
    getAndPopulateLabelCounts(templates, getLabelsQuery, assocProp, false)]
    );
    dispatch(
      actions.set('thesauri.suggestInfo', {
        model,
        property: assocProp,
        docsWithSuggestionsForPublish,
        docsWithSuggestionsForReview,
        docsWithLabels
      })
    );
    dispatch(
      actions.set('thesauri.tasksState', { SyncState: syncState.json, TrainState: trainState })
    );
  };
}

export function startTraining() {
  return async (dispatch, getState) => {
    const thesaurus = getState().thesauri.thesaurus.toJS();
    await ThesauriAPI.trainModel(new RequestParams({ thesaurusId: thesaurus._id.toString() }));
    await dispatch(updateCockpitData());
  };
}