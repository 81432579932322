
import { SettingsAPI } from "../../../Settings";
import { RequestParams } from "../../../utils/RequestParams";

const get = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  const response = SettingsAPI.get(requestParams);
  return response;
};

export { get };