function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}
import { actions as formActions } from 'react-redux-form';

import entitiesAPI from "../../Entities/EntitiesAPI";
import { actions } from "../../BasicReducer";
import { notificationActions } from "../../Notifications";
import { t } from "../../I18N";
import { RequestParams } from "../../utils/RequestParams";


const getAndUpdateCoercedValue = async (params, model) => {
  const { value: coercedValue, success } = await entitiesAPI.coerceValue(params);
  if (!success) {
    return notificationActions.notify(
      t('System', 'Value cannot be transformed to the correct type', null, false),
      'danger'
    );
  }
  return formActions.change(model, coercedValue);
};

const updateSelection = (
selection,
fieldName,
fieldId) =>
{
  const selected = _objectSpread({}, selection);

  const data = _objectSpread(_objectSpread({},
  fieldId && { propertyID: fieldId }), {}, {
    name: fieldName,
    timestamp: Date(),
    selection: selected });


  return actions.updateIn('documentViewer.metadataExtraction', ['selections'], data, 'propertyID');
};

const deleteSelection =
(entityDocument, propertyName, propertyID) =>
(dispatch) => {var _document$extractedMe;
  const document = entityDocument === null || entityDocument === void 0 ? void 0 : entityDocument.toJS();

  const updatedSelections = document === null || document === void 0 ? void 0 : (_document$extractedMe = document.extractedMetadata) === null || _document$extractedMe === void 0 ? void 0 : _document$extractedMe.filter(
    (selection) =>
    propertyName === 'title' && selection.name !== 'title' ||
    selection.propertyID !== propertyID
  );

  const data = _objectSpread(_objectSpread({},
  propertyID && { propertyID }), {}, {
    name: propertyName,
    selection: { text: '', selectionRectangles: [] },
    deleteSelection: true });


  return [
  dispatch(
    actions.setIn('viewer/doc', 'defaultDoc', _objectSpread(_objectSpread({},
    document), {}, {
      extractedMetadata: updatedSelections })
    )
  ),
  dispatch(
    actions.updateIn('documentViewer.metadataExtraction', ['selections'], data, 'propertyID')
  )];

};

const updateFormField = async (
value,
model,
fieldType,
locale) =>
{
  if (fieldType === 'date') {
    const requestParams = new RequestParams({
      locale,
      value,
      type: 'date'
    });
    return getAndUpdateCoercedValue(requestParams, model);
  }

  if (fieldType === 'numeric') {
    const requestParams = new RequestParams({
      locale,
      value: value.trim(),
      type: 'numeric'
    });
    return getAndUpdateCoercedValue(requestParams, model);
  }

  const requestParams = new RequestParams({
    locale,
    value,
    type: 'text'
  });
  return getAndUpdateCoercedValue(requestParams, model);
};

export { updateSelection, updateFormField, deleteSelection };