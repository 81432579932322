function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import Immutable from 'immutable';
import { actions } from "../../BasicReducer";
import { RequestParams } from "../../utils/RequestParams";

import api from "../SemanticSearchAPI";

export function fetchSearches() {
  return (dispatch) =>
  api.getAllSearches().then((response) => {
    dispatch(actions.set('semanticSearch/searches', response));
  });
}

export function setEditSearchEntities(entities = []) {
  return (dispatch) => {
    dispatch(actions.set('semanticSearch/multiedit', entities));
  };
}

export function editSearchEntities(searchId, args) {
  const requestParams = new RequestParams(_objectSpread({ searchId }, args));
  return (dispatch) =>
  api.getEntitiesMatchingFilters(requestParams).then((response) => {
    dispatch(setEditSearchEntities(response));
  });
}

export function selectSemanticSearchDocument(doc) {
  return (dispatch) => {
    dispatch(actions.set('semanticSearch/selectedDocument', doc));
    dispatch(actions.set('library.sidepanel.tab', 'semantic-search-results'));
  };
}

export function unselectSemanticSearchDocument() {
  return (dispatch) => {
    dispatch(actions.set('semanticSearch/selectedDocument', {}));
  };
}

function sanitizeSearchFilters(filters) {
  return Object.keys(filters).reduce((partial, key) => {
    const emptyFilter =
    !filters[key] || typeof filters[key] === 'object' && !Object.keys(filters[key]).length;
    if (emptyFilter) {
      return partial;
    }
    return _objectSpread(_objectSpread({}, partial), {}, { [key]: filters[key] });
  }, {});
}

export function submitNewSearch(args) {
  const { searchTerm } = args;
  const query = _objectSpread(_objectSpread({}, args), {}, { searchTerm: '', filters: sanitizeSearchFilters(args.filters) });

  return (dispatch) =>
  api.search(new RequestParams({ searchTerm, query })).then(() => dispatch(fetchSearches()));
}

export function showSemanticSearch() {
  return (dispatch) => {
    dispatch(actions.set('semanticSearch/showSemanticSearchPanel', true));
  };
}

export function hideSemanticSearch() {
  return (dispatch) => {
    dispatch(actions.set('semanticSearch/showSemanticSearchPanel', false));
  };
}

export function deleteSearch(searchId) {
  return (dispatch) =>
  api.deleteSearch(new RequestParams({ searchId })).then(() => {
    dispatch(fetchSearches());
  });
}

export function stopSearch(searchId) {
  return (dispatch) =>
  api.stopSearch(new RequestParams({ searchId })).then(() => {
    dispatch(fetchSearches());
  });
}

export function resumeSearch(searchId) {
  return (dispatch) =>
  api.resumeSearch(new RequestParams({ searchId })).then(() => {
    dispatch(fetchSearches());
  });
}

export function registerForUpdates() {
  return () => api.registerForUpdates();
}

export function updateSearch(updatedSearch) {
  return (dispatch) => dispatch(actions.update('semanticSearch/searches', updatedSearch));
}

export function addSearchResults(newDocs) {
  return (dispatch, getState) => {
    const currentSearch = getState().semanticSearch.search;
    const newResults = currentSearch.update('results', (existingDocs) =>
    newDocs.reduce((updatedDocs, newDoc) => {
      if (!updatedDocs.find((d) => newDoc._id === d.get('_id'))) {
        return updatedDocs.push(Immutable.fromJS(newDoc));
      }
      return updatedDocs;
    }, existingDocs)
    );
    dispatch(actions.set('semanticSearch/search', newResults));
  };
}

export function getSearch(searchId, args) {
  const requestParams = new RequestParams(_objectSpread({ searchId }, args));
  return (dispatch, getState) =>
  api.getSearch(requestParams).then((search) => {
    dispatch(actions.set('semanticSearch/search', search));
    const selectedDoc = getState().semanticSearch.selectedDocument;
    if (selectedDoc) {
      const updatedDoc = search.results.find((doc) => doc.sharedId === selectedDoc.get('sharedId'));
      if (updatedDoc) {
        dispatch(actions.set('semanticSearch/selectedDocument', updatedDoc));
      }
    }
  });
}

export function getMoreSearchResults(searchId, args) {
  const requestParams = new RequestParams(_objectSpread({ searchId }, args));
  return (dispatch) =>
  api.
  getSearch(requestParams).
  then((search) =>
  dispatch(actions.concatIn('semanticSearch/search', ['results'], search.results))
  );
}