
import api from "../utils/api";



const getSuggestions = async (requestParams) => {
  const { json: response } = await api.get('suggestions', requestParams);
  return {
    suggestions: response.suggestions,
    aggregations: response.aggregations,
    totalPages: response.totalPages
  };
};

const getStats = async (requestParams) => {
  const { json: response } = await api.get('suggestions/stats', requestParams);
  return response;
};

const trainModel = async (requestParams) => {
  const { json: response } = await api.post('suggestions/train', requestParams);
  return response;
};

const cancelFindingSuggestions = async (requestParams) => {
  const { json: response } = await api.post('suggestions/stop', requestParams);
  return response;
};

const ixStatus = async (requestParams) => {
  const { json: response } = await api.post('suggestions/status', requestParams);
  return response;
};

const acceptEntitySuggestion = async (requestParams) => {
  const { json: response } = await api.post('suggestions/accept', requestParams);
  return response;
};

const getAllExtractors = async (requestParams) => {
  const { json: response } = await api.get('ixextractors', requestParams);
  return response;
};

const createExtractor = async (requestParams) => {
  const { json: response } = await api.post('ixextractors', requestParams);
  return response;
};

const deleteExtractors = async (requestParams) => {
  const { json: response } = await api.delete('ixextractors', requestParams);
  return response;
};

const updateExtractor = async (requestParams) => {
  const { json: response } = await api.put('ixextractors', requestParams);
  return response;
};

export {
  getSuggestions,
  getStats,
  trainModel,
  cancelFindingSuggestions,
  ixStatus,
  acceptEntitySuggestion,
  getAllExtractors,
  createExtractor,
  deleteExtractors,
  updateExtractor };