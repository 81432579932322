function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}

import { I18NApi } from "../../../I18N";

import { RequestParams } from "../../../utils/RequestParams";
import { httpRequest } from "../../../../shared/superagent";
import loadingBar from "../../../App/LoadingProgressBar";

const filterTranslationsByContext = (
translations,
contextId) =>

translations.map((language) => {
  const contexts = language.contexts.filter((context) => context.id === contextId);
  return _objectSpread(_objectSpread({}, language), {}, { contexts });
});

const get = async (
headers,
params) =>
{
  const requestParams = new RequestParams(_objectSpread({}, params), headers);
  const response = I18NApi.get(requestParams);
  return response;
};

const post = async (updatedTranslations, contextId) => {
  try {
    const translations = await Promise.all(
      updatedTranslations.map((language) => I18NApi.save(new RequestParams(language)))
    );
    return filterTranslationsByContext(translations, contextId);
  } catch (e) {
    return e;
  }
};

const importTranslations = async (
file,
contextId) =>
{
  loadingBar.start();
  try {
    const translations = await httpRequest(
      'translations/import',
      { context: contextId },
      {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      file
    );
    return filterTranslationsByContext(translations, contextId);
  } catch (e) {
    return e;
  } finally {
    loadingBar.done();
  }
};

const { getLanguages } = I18NApi;

export { get, post, importTranslations, getLanguages };