function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import { actions } from "../../BasicReducer";
import { actions as formActions } from 'react-redux-form';
import { notificationActions } from "../../Notifications";
import referencesAPI from "../../Viewer/referencesAPI";
import { fromJS as Immutable } from 'immutable';
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { RequestParams } from "../../utils/RequestParams";

export function search(requestParams) {
  const { sharedId, sort, filters } = requestParams.data;
  const searchTerm =
  requestParams.data.search && requestParams.data.search.searchTerm ?
  requestParams.data.search.searchTerm.value :
  '';

  let options = _objectSpread({ sharedId }, sort);
  if (filters) {
    options = _objectSpread(_objectSpread(_objectSpread({ sharedId }, sort), filters.toJS()), {}, { searchTerm });
  }
  return referencesAPI.search(requestParams.onlyHeaders().add(options));
}

export function searchReferences() {
  return async (dispatch, getState) => {
    const relationshipsList = getState().relationships.list;
    const results = await search(new RequestParams(relationshipsList));
    dispatch(actions.set('relationships/list/searchResults', results));
  };
}

export function connectionsChanged(sharedId) {
  return (dispatch, getState) => {
    dispatch(actions.set('relationships/list/filters', { limit: 10 }));
    const relationshipsList = getState().relationships.list;
    let innerSharedId = sharedId;
    if (!innerSharedId) {
      innerSharedId = relationshipsList.sharedId;
    }
    return referencesAPI.
    getGroupedByConnection(new RequestParams({ sharedId: innerSharedId })).
    then((connectionsGroups) => {
      const filteredTemplates = connectionsGroups.reduce(
        (templateIds, group) => templateIds.concat(group.templates.map((t) => t._id.toString())),
        []
      );

      const sortOptions = prioritySortingCriteria.get({
        currentCriteria: relationshipsList.sort,
        filteredTemplates,
        templates: getState().templates
      });
      return Promise.all([connectionsGroups, sortOptions]);
    }).
    then(([connectionsGroups, sort]) => {
      dispatch(actions.set('relationships/list/connectionsGroups', connectionsGroups));
      dispatch(actions.set('relationships/list/sharedId', sharedId));
      dispatch(formActions.merge('relationships/list.sort', sort));
      return searchReferences()(dispatch, getState);
    });
  };
}

export function deleteConnection(connection) {
  return async (dispatch, getState) => {
    await referencesAPI.delete(new RequestParams({ _id: connection._id }));
    dispatch(notificationActions.notify('Connection deleted', 'success'));
    return connectionsChanged()(dispatch, getState);
  };
}

export function loadAllReferences() {
  return async (dispatch, getState) => {
    const relationshipsList = getState().relationships.list;
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('limit', 9999))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function loadMoreReferences(limit) {
  return function (dispatch, getState) {
    const relationshipsList = getState().relationships.list;
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('limit', limit))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function setFilter(groupFilterValues) {
  return function (dispatch, getState) {
    const relationshipsList = getState().relationships.list;
    const currentFilter = relationshipsList.filters.get('filter') || Immutable({});
    const newFilter = currentFilter.merge(groupFilterValues);
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('filter', newFilter))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function resetSearch() {
  return function (dispatch, getState) {
    dispatch(formActions.change('relationships/list/search.searchTerm', ''));
    dispatch(actions.set('relationships/list/filters', Immutable({})));
    return searchReferences()(dispatch, getState);
  };
}

export function switchView(type) {
  return function (dispatch, getState) {
    dispatch(actions.set('relationships/list/view', type));
    if (type === 'graph') {
      return loadAllReferences()(dispatch, getState);
    }
  };
}