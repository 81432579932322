function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import * as types from "./actionTypes";
import libraryHelper from "../helpers/libraryFilters";
import comonPropertiesHelper from "../../../shared/comonProperties";
import * as libraryActions from "./libraryActions";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { actions as formActions } from 'react-redux-form';

export function filterDocumentTypes(documentTypes, location, navigate) {
  return (dispatch, getState) => {
    const state = getState();

    const templates = state.templates.toJS();
    const thesauris = state.thesauris.toJS();

    let libraryFilters = comonPropertiesHelper.
    comonProperties(templates, documentTypes).
    filter((prop) => prop.filter);
    libraryFilters = libraryHelper.populateOptions(libraryFilters, thesauris);

    const usefulTemplates = documentTypes.length ?
    templates.filter((t) => documentTypes.includes(t._id)) :
    templates;

    const { sort, order } = prioritySortingCriteria.get({
      currentCriteria: { sort: state.library.search.sort, order: state.library.search.order },
      filteredTemplates: usefulTemplates.map((t) => t._id),
      templates: state.templates,
      selectedSorting: state.library.selectedSorting
    });

    const search = _objectSpread(_objectSpread({
      types: documentTypes },
    state.library.search), {}, {
      sort,
      order });


    const filters = { documentTypes, properties: libraryFilters };
    dispatch(libraryActions.searchDocuments({ filters, search, location, navigate }));
  };
}

export function resetFilters(navigate, location) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_LIBRARY_FILTERS, documentTypes: [], libraryFilters: [] });
    dispatch(
      formActions.load('library.search', {
        searchTerm: '',
        filters: {},
        order: 'desc',
        sort: 'creationDate'
      })
    );
    libraryActions.searchDocuments({ navigate, location })(dispatch, getState);
  };
}

export function toggleFilter(propertyName, properties) {
  return (dispatch) => {
    const updatedProperties = properties.map((property) => {
      if (property.name === propertyName) {
        property.active = !property.active;
      }
      return property;
    });
    dispatch({ type: types.UPDATE_LIBRARY_FILTERS, libraryFilters: updatedProperties });
  };
}