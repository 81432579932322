import { atom } from 'recoil';




const notificationAtom = atom({
  key: 'Notification',
  default: {}
});


export { notificationAtom };