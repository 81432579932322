

import { t } from "../../I18N";
import { notificationActions } from "../../Notifications";

import { RequestParams } from "../../utils/RequestParams";
import api from "../../utils/api";
import { acceptEntitySuggestion } from "../SuggestionsAPI";
import EntitiesAPI from "../../Entities/EntitiesAPI";
import scroller from "../../Viewer/utils/Scroller";
import { actions } from "../../BasicReducer";

import {
  getAllExtractors,
  createExtractor as createExtractorAPICall,
  deleteExtractors as deleteExtractorsAPICall } from "../SuggestionsAPI";


const loadExtractors = () => async (dispatch) => {
  const extractors = await getAllExtractors(new RequestParams());
  dispatch(actions.set('ixExtractors', extractors));
};

const loadExtractor = async (query, headers = {}) => {
  const extractors = await getAllExtractors(new RequestParams(query, headers));
  return extractors;
};

const createExtractor = (newExtractorInfo) => async (dispatch) => {
  const extractor = await createExtractorAPICall(new RequestParams(newExtractorInfo));
  dispatch(actions.push('ixExtractors', extractor));
};

const deleteExtractors =
(currentExtractors, extractorIds) => async (dispatch) => {
  await deleteExtractorsAPICall(new RequestParams({ ids: extractorIds }));
  const idSet = new Set(extractorIds);
  const newExtractors = currentExtractors.filter(
    (extractor) => extractor._id && !idSet.has(extractor._id)
  );
  dispatch(actions.set('ixExtractors', newExtractors));
};

const fetchEntity = async (entityId, language) => {
  const entityRequest = new RequestParams({ _id: entityId });
  return EntitiesAPI.get(entityRequest, language);
};

const fetchFile = async (fileId) => {
  const fileRequest = new RequestParams({ _id: fileId });
  return api.get('files', fileRequest);
};

const scrollToPage = async (pageNumber) =>
scroller.to(`.document-viewer div#page-${pageNumber}`, '.document-viewer', {
  duration: 0,
  dividerOffset: 1,
  offset: 50
});

const acceptSuggestion =
(suggestion, allLanguages) =>
async (dispatch) => {
  const params = new RequestParams({
    allLanguages,
    suggestion: {
      _id: suggestion._id,
      sharedId: suggestion.sharedId,
      entityId: suggestion.entityId
    }
  });
  const result = await acceptEntitySuggestion(params);
  if (result.success) {
    dispatch(
      notificationActions.notify(t('System', 'Saved successfully.', null, false), 'success')
    );
  }
};

export {
  acceptSuggestion,
  loadExtractors,
  loadExtractor,
  createExtractor,
  deleteExtractors,
  fetchEntity,
  fetchFile,
  scrollToPage };