
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";

const get = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const response = await api.get('templates', requestParams);
    return response.json.rows;
  } catch (e) {
    return e;
  }
};

export { get };